.leadership-team-container {
    text-align: center;
    margin: 20px;
  }
  
  .leadership-team-title {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .leadership-team-image {
    width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  