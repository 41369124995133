.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
}

.footer-section {
  margin-bottom: 20px;
}

.footer-heading {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 1.5rem;
  color: white;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #ccc;
}

.contact-info p {
  margin: 0;
}

.footer-bottom {
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
}
