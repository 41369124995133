/* src/components/Marquee.css */
.marquee-container {
    width: 100%;
    background-color: #f8f9fa;
    padding: 10px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    overflow: hidden;
  }
  
  .marquee-text {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 20s linear infinite; /* Slow animation */
  }
  
  .marquee-text a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(-100%); /* Start off-screen to the left */
    }
    100% {
      transform: translateX(100%); /* End off-screen to the right */
    }
  }
  